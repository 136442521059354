// Uikit Radio start
.uk-radio{
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  border-color: $grey_50 !important;
  border-width: 2px;
  background-image: none !important;
  &:checked{
    background-size: 110px !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E") !important;
  }
}

// Uikit Checkbox start
.uk-checkbox{
  height: 38px;
  width: 38px;
  border-radius: 3px;
  border-color: $white_40 !important;
  background-color: transparent !important;
  &:checked{
    background-size: contain;
    background-color: $white !important;
    border-color: $white_40 !important;
  }
}

// Custom Checkbox
.uk-checkbox-custom {
  position: relative;
  margin: 0px;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    + span {
      width: 38px;
      height: 38px;
      display: block;
      border: 1px solid $white_40;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
    }
    &:checked + span {
      border-color: $white_40;
      background: $white;
      &:after, &:before {
        position: absolute;
        left: 4px;
        top: 16px;
        height: 12px;
        width: 4px;
        background-color: $grey_70;
        content: "";
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
      }
      &:before {
        top: 24px;
        bottom: 20px;
        height: 4px;
        width: 22px;
      }
    } 
  }
}
// Checkbox End

// Simple HTML/CSS switch
.switch{
  display: inline-block;
  position: relative;
  width: 76px;
  height: 32px;
  border-radius: 20px;
  // background: $white_30;
  background: $grey_40 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px $black_90;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  &:active{
    &::before {
      box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
    }
  }
  &::before{
    content: '';
    position: absolute;
    top: 1px;
    right: 2px;
    width: 30px;
    height: 30px;
    background: $white_20;
    border-radius: 50%;
    transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
input:checked + .switch{
  // background: $grey_50;
  background: $grey_40 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px $black_90;
}
input:checked + .switch::before{
  right: 45px;
  background: $white;
}
input:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}