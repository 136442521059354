$white: #FFFFFF;
$white_40: rgba(255, 255, 255, .7);
$white_30: #dfd9ea;
$white_20: #fafafa;
$white_15: #C8C8C8;
$white_10: #f1f1f1;


$black: #000000;
$black_90: rgba(0, 0, 0, 0.16);
$black_80: rgba(0, 0, 0, 0.35);
$black_56: rgba(0, 0, 0, 0.56);

$grey_95: #302F2F;
$grey_90: #454545;
$grey_80: #474747;
$grey_70: #676666;
$grey_65: #707070;
$grey_60: #757575;
$grey_55: #808080;
$grey_50: #8C8C8C;
$grey_40: #A5A5A5;
$grey_30: #AEAEAE;
$grey_20: #E6E6E6;
$grey_10: #D6D6D6;
$grey_05: #F5F5F5;

$red: #FF0000;
$red_90: #F20000;
$red_50: #D6646C;
$red_80: #5b1015;
$red_70: #c5646a;
$red_60: #ed9ba0;

$orange: #FF9224;
$orange_90: #ff8000;
$orange_40: #F6CC98;


$green: #95C248;
$green_50: #2f4c12;
$green_10: #8fad73;
$green_5: #bddd9e;

$blue_40: #3A4250;

$turquoise: #1BCCC0;

$border-color_1: rgb(72, 71, 71) transparent transparent transparent;
$map_marker_tooltip: #748994