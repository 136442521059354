@import 'color';
@import 'typography';
@import 'uikit-custom';
@import 'custom';
*,
::after,
::before {
  box-sizing: border-box;
}

// Custom Scrollbar start
:root {
  // scrollbar-color: $grey_50 $grey_80!important;
  scrollbar-width: thin !important;
  --side-menu-width: 172px;
}

* {
  scrollbar-width: 10px;
  scrollbar-color: $grey_80;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: $grey_80;
}

*::-webkit-scrollbar-thumb {
  background-color: $white_15;
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background: $black;
}

// Custom Scrollbar end

body {
  font-family: 'Noto Sans JP', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: $grey_80;
}

.text-dark {
  color: $black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

// Colors
.color-white {
  color: $white !important;
}

.color-black {
  color: $black;
}

.color-grey-50 {
  color: $grey_50;
}

// BG Colors
.bg-white {
  background-color: $white;
}

.bg-black {
  background-color: $black;
}

.bg-grey-80 {
  background-color: $grey_80;
}

// Font Sizes
.font-44 {
  font-size: 44px;
}

.font-42 {
  font-size: 42px;
}

.font-38 {
  font-size: 38px;
}

.font-37 {
  font-size: 37px;
}

.font-35 {
  font-size: 35px;
}

.font-32 {
  font-size: 32px;
}

.font-30 {
  font-size: 30px;
}

.font-28 {
  font-size: 28px;
}

.font-25 {
  font-size: 25px;
}

.font-24 {
  font-size: 24px;
}

.font-23 {
  font-size: 23px;
}

.font-22 {
  font-size: 22px;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

// Padding Spacing
.pt-22 {
  padding-top: 22px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-14 {
  padding-top: 14px;
}

.pb-24 {
  padding-bottom: 24px;
}

// Margin Spacing
.mt-72 {
  margin-top: 72px;
}

.mt-38 {
  margin-top: 38px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-66 {
  margin-right: 66px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-58 {
  margin-right: 58px;
}

.mr-52 {
  margin-right: 52px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-custom {
  margin-right: 6.1px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-2 {
  margin-bottom: 2px;
}

// gap
.column-gap-15 {
  column-gap: 15px !important;
}

.column-gap-10 {
  column-gap: 10px !important;
}

.column-gap-20 {
  column-gap: 20px !important;
}

// Width
.w-500 {
  width: 500px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.w-full {
  width: 100%;
}

// Min-width
.min-w-554 {
  min-width: 554px;
}

.min-w-224 {
  min-width: 224px;
}

.min-w-172 {
  min-width: 172px;
}

.min-w-146 {
  min-width: 146px;
}

// Min-height
.min-h-100vh {
  min-height: 100vh;
}

// Max-height
.max-h-300 {
  max-height: 300px;
}

.max-h-624 {
  max-height: 624px;
}

// Position => top, right, bottom, left,
.uk-top-70 {
  top: 70px !important;
}

.uk-top-60 {
  top: 60px !important;
}

.uk-left-0 {
  left: 0 !important;
}

.uk-bottom-0 {
  bottom: 0;
}

.cluster-tooltip-lines {
  display: flex;
  flex-direction: row;
  // align-items: center;
}

// Start SCSS
.uk-button {
  &.uk-btn-login {
    width: 380px;
    height: 60px;
    border-radius: 37px;
    font-size: 36px;
    line-height: 54px;
    text-transform: lowercase;
    letter-spacing: 1.5px;
  }

  &.uk-button-orange {
    background: $orange 0% 0% no-repeat padding-box;
    border-radius: 35px;
    height: 55px;
    padding: 0px 38px 0px 18px;

    &:hover {
      background-color: $orange_90;
      transition: 0.5s;
    }
  }

  &.uk-btn-logout {
    background: $grey_65 0% 0% no-repeat padding-box;
    border: 1px solid $grey_65;
    border-radius: 25px;
  }

  &.btn-active-mode {
    background-color: #8c8c8c !important;

    &:hover {
      background-color: #6d6d6d !important;
    }
  }

  &.btn-active-mode {
    background-color: #8c8c8c !important;

    &:hover {
      background-color: #6d6d6d !important;
    }
  }
}

.uk-nav {
  .uk-dropdown {
    color: $white;
    padding: 24px 30px 35px 58px;
    background: $grey_80 0% 0% no-repeat padding-box;
    box-shadow: $black_90 2px 2px 16px 0px, $black_90 1px 1px 10px 0px;
    border-radius: 10px;

    .uk-navbar-dropdown-nav {
      li {
        a {
          color: $white;
          font: normal normal normal 24px/36px Arial;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .uk-language {
    .uk-dropdown {
      width: 308px;
    }
  }

  .uk-power-plant {
    .power-plant {
      padding: 22px 10px 18px 52px;

      ul {
        max-height: 440px;
        overflow: auto;
        padding-right: 10px;
      }
    }
  }

  .uk-aki-suzuki {
    .uk-dropdown {
      // top: 90px !important;
      padding: 22px 60px 34px 40px;
      min-width: 300px;
      width: fit-content;
    }

    .user-pic {
      min-width: 160px;
      height: fit-content;
      min-width: 160px;
      height: fit-content;
      border-radius: 50%;
      box-shadow: $black_80 0px -5px 20px;
      overflow: hidden;
    }
  }

  .uk-menu-icn {
    .menu-icn {
      padding: 15px 12px 15px 15px;
      left: auto !important;
      right: -24px;
      width: 500px;

      .uk-nav-default {
        li {
          .selected-bg {
            padding: 6px 10px 6px 26px;
            margin-right: 20px;

            .arrow-rotetion {
              transform: rotate(90deg);
              transition: 0.3s ease;
              transform-origin: center center;
            }
          }

          &.uk-open {
            .selected-bg {
              background: $grey_70;
              border-radius: 24px;
              margin-bottom: 10px;

              .arrow-rotetion {
                transform: rotate(0deg);
              }
            }
          }

          .uk-nav-sub {
            li {
              padding: 6px 20px;
              margin-top: 4px;

              &.select-item {
                background: $grey_70;
                border-radius: 50px;
              }

              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &.select-disaster {
    border: 1px solid $grey_05;
    border-radius: 9px;
    opacity: 1;
    padding: 2px 10px;
    min-width: 360px;

    li {
      a {
        img {
          right: 10px;
        }
      }
    }

    .uk-dropdown {
      padding: 32px 24px 34px 36px;
    }
  }
}

.side-menu {
  position: fixed;
  left: 0;
  width: var(--side-menu-width);
  height: calc(100vh - 317px);
  background-color: rgba(71, 71, 71, 0.7);
  overflow-y: auto;
  padding-top: 20px;
  z-index: 1;
  .sd-box {
    margin-bottom: 20px;

    p {
      margin: 0px;
    }
  }

  .compass-box {
    margin-bottom: 20px !important;
  }
}

// Map
.uk-map {
  height: calc(100vh - 317px);
  background: $grey_70;
  overflow-y: hidden;
  padding: 0px;
  margin: 0px;

  .avtar {
    .red-user {
      left: 32%;
      top: 35%;
      z-index: 1;
      cursor: pointer;

      &:hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
        }
      }

      .tooltip-text {
        visibility: hidden;
        position: absolute;
        padding: 15px;
        width: 400px;
        border-radius: 10px;
        background-color: $grey_80;
        z-index: 1;
        bottom: 70px;
        left: 36px;
        opacity: 0;
        transition: opacity 1s;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 14px;
          bottom: -50px;
          width: 0;
          height: 0;
          transform: rotate(-160deg);
          border-bottom: 50px solid $grey_80;
          border-top: 10px solid transparent;
          border-left: 30px solid transparent;
          border-right: 10px solid transparent;
        }
      }
    }

    .danger-user {
      left: 31%;
      top: 32%;
    }

    .user-plus {
      left: 50%;
      bottom: 160px;
      cursor: pointer;

      &:hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
        }
      }

      .tooltip-text {
        max-width: 140px;
        visibility: hidden;
        position: absolute;
        padding: 15px;
        width: 400px;
        border-radius: 10px;
        background-color: $grey_80;
        z-index: 1;
        bottom: 120px;
        left: 60px;
        opacity: 0;
        transition: opacity 1s;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 14px;
          bottom: -50px;
          width: 0;
          height: 0;
          transform: rotate(-160deg);
          border-bottom: 50px solid $grey_80;
          border-top: 10px solid transparent;
          border-left: 30px solid transparent;
          border-right: 10px solid transparent;
        }
      }
    }
  }

  .img-map {
    width: 100%;
    display: block;
    overflow-y: hidden;
  }
}

.footer {
  min-height: 124px;
  z-index: 9999;

  .f-box {
    label {
      input {
        &.uk-checkbox {
          width: 26px;
          height: 26px;
          position: relative;
          top: -3px;
          position: relative;
          top: -3px;
        }
      }
    }

    .uk-dropdown {
      transform: translateY(-100%);
      padding: 0px;
      min-width: 450px;
      width: auto;

      ul {
        overflow-y: scroll;
        max-height: 330px;
        padding: 24px;

        .uk-checkbox {
          height: 38px;
          width: 38px;
        }

        .uk-checkbox {
          height: 38px;
          width: 38px;
        }
      }

      .company-selection {
        box-shadow: 0px 2px 2px $black_90;
        padding: 10px 10px 10px 22px;
        font-size: 18px !important;
      }
    }

    .f-box-value {
      font-size: 30px;
    }

    > ul {
      > li {
        > a {
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    > ul {
      > li {
        > a {
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.font-21 {
  font-size: 21px;
}

.font-19 {
  font-size: 19px;
}

.font-34 {
  font-size: 34px;
}

.font-36 {
  font-size: 36px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-56 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-5 {
  margin-left: 5px;
}

.mb-44 {
  margin-bottom: 44px;
}

.ml-64 {
  margin-left: 64px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-54 {
  margin-left: 54px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-28 {
  margin-left: 28px;
}

.left-auto {
  left: auto !important;
}

.column-gap-60 {
  column-gap: 40px;
}

.column-gap-40 {
  column-gap: 40px;
}

.column-gap-30 {
  column-gap: 30px;
}

.text-wrap {
  text-wrap: nowrap;
}

.pb-14 {
  padding-bottom: 14px;
}

.min-w-200 {
  min-width: 200px;

  &:nth-child(3) {
    min-width: 150px;
  }
}

.min-w-110 {
  min-width: 110px;
}

.min-w-100 {
  min-width: 100px;
}
.min-w-150 {
  min-width: 150px;
}
.min-w-160 {
  min-width: 160px;
}

.min-w-132 {
  min-width: 132px;
}

// QR Code
.qr-area {
  height: calc(100vh - 141px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .qr-box {
    margin: 20px;

    .qr-img {
      width: 300px;
    }

    .qr-name {
      margin: 10px 0px;
      font-size: 18px;
      text-align: center;
    }
  }
}

// Table area
.table-area {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100%);
  height: calc(100vh - 314px);
  z-index: 100;
  background-color: #474747;
  padding-right: 26px;
  display: none;
  flex-direction: column;

  &.table-minimize {
    // top: 193.5px;
    transform: scale(0.5);
    padding: 0px 20px 20px;
    right: 10px;
    top: 200px;
    transform-origin: top right;
  }

  &.table-show {
    display: flex;
  }
}

.table-data {
  flex: 1;

  .uk-table-bordered {
    border: 1px solid $white_15;

    thead {
      &.uk-thead-sticky {
        position: sticky;
        box-shadow: inset 0 1px 0 $grey_50, inset 0 0px 0 $grey_70;
        background-color: $grey_50;
        top: -1px;
        z-index: 1;
      }

      tr {
        th {
          border: 1px solid $white_15;
          outline: 1px solid $white_15;
          font-size: 24px;
          vertical-align: middle;
          vertical-align: middle;
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid $white_15;
          font-size: 18px;
          vertical-align: middle;
          vertical-align: middle;
        }
      }
    }
  }
}

.bg-grey-50 {
  background-color: $grey_50;
}

.w-310 {
  width: 310px;
}

// modal area
.modal-area {
  z-index: 1;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 193px;
  top: 193px;
  width: 100%;
  height: calc(100% - 317px);
  height: calc(100% - 317px);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);

  &.without-overlay-bg {
    background-color: transparent;
  }

  .modal-padding {
    padding: 24px 20px;

    &.uk-border-rounded {
      border-radius: 8px;
    }
  }

  .modal-pill-container {
    margin: 40px 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .modal-pill {
    max-width: 350px;
    width: 49%;
    padding: 6px 10px;
    border-radius: 8px;
  }

  .modal-pill,
  .modal-dialog .uk-button-link.modal-pill[data-id=yesButton],
  .modal-dialog .uk-button-link.modal-pill[data-id=noButton] {
    font-size: 24px !important;
    height: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media screen and (max-width: 1300px) {
    .modal-pill {
      font-size: 14px !important;
      width: 100%;
    }
    .modal-dialog .uk-button-link.modal-pill[data-id=yesButton],
    .modal-dialog .uk-button-link.modal-pill[data-id=noButton] {
      font-size: 25px !important;
    }
  }
  
  &.modal-tsunami-earthquake {
    z-index: 99999;
    height: 100%;
    top: 0;

    .modal-footer {
      justify-content: center;
      gap: 10px;
      margin-top: 40px;
    }

    .modal-dialog {
      border: 0.1px solid $grey_95;
    }
  }
  

  .modal-dialog {
    width: 1000px !important;
    padding: 24px 20px;
    border-radius: 12px;

    .modal-title-3 {
      font-size: 50px;
      line-height: 1;
    }

    .modal-title-3 {
      font-size: 50px;
      line-height: 1;
    }

    .warning-area {
      .square-repetition {
        width: 100%;
        height: 50px;
        background: url('../images/icn-warning.svg') repeat-x left;
      }

      .warning-heading {
        font-size: 54px;
        font-size: 54px;
      }
    }

    .modal-body {
      .modal-title {
        font-size: 100px;
        font-weight: bold;
      }

      .modal-title-2 {
        font-size: 70px;
      }

      .modal-title-3 {
        font-size: 60px;
      }

      .modal-title-4 {
        font-size: 70px;
      }

      .modal-description {
        font-size: 45px;
      }

      .modal-footer {
        button {
          font-size: 45px;
        }
      }
    }

    .modal-h2-title {
      font-weight: 600 !important;
      margin-bottom: 0px !important;

      &.modal-h2-title-lg {
        margin-bottom: 40px !important;
      }
    }

    .modal-body {
      .modal-title {
        font-size: 100px;
        font-weight: bold;
      }

      .modal-title-2 {
        font-size: 70px;
      }

      .modal-title-3 {
        font-size: 60px;
      }

      .modal-title-4 {
        font-size: 70px;
      }

      .modal-description {
        font-size: 45px;
      }

      .modal-footer {
        button {
          font-size: 45px;
        }
      }
    }

    .modal-h2-title {
      font-weight: 600 !important;
      margin-bottom: 0px !important;

      &.modal-h2-title-lg {
        margin-bottom: 40px !important;
        font-size: 50px;
      }
    }

    .modal-p-text {
      font-size: 40px !important;
      line-height: 1;
      margin: 15px 0px 60px;
    }

    .uk-button-link {
      font-size: 40px;

      &:hover {
        color: #ff0000 !important;
      }
    }

    .modal-footer {
      margin-bottom: 30px !important;
    }

    .modal-p-text {
      font-size: 40px !important;
      line-height: 1;
      margin: 15px 0px 60px;
    }

    .uk-button-link {
      font-size: 40px;

      &:hover {
        color: #ff0000 !important;
      }
    }

    .modal-footer {
      margin-bottom: 30px !important;
    }
  }
}

.bg-grey-95 {
  background-color: $grey_95;
}

.color-red {
  color: $red;
}


.color-orange {
  color: $orange;
}

.color-green {
  color: $turquoise;
}

.mt-86 {
  margin-top: 86px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-20 {
  margin-left: 20px;
}

.column-gap-22 {
  column-gap: 22px;
}

.disaster-location {
  z-index: 1;
  top: 224px;
  left: var(--side-menu-width);
  right: 0;
  z-index: 1;
  top: 224px;
  right: 0;
  width: fit-content;
  max-width: calc(100vw - 2*var(--side-menu-width)); // screen width - space taken by padding
  margin: 0 auto;
  margin: 0 auto;

  .title {
    font-size: 50px;
  }

  .disaster-location-inner {
    border-radius: 1.2rem;
  }
}

.min-h-530 {
  min-height: 530px;
}

.bottom-right {
  .uk-checkbox {
    position: relative;
    top: 2px;
  }
}

.acn-btns {
  > li {
    > a {
      &:hover {
        opacity: 0.7 !important;
      }
    }
  }

  .icn-down-arrow {
    position: relative;
    top: 4px;
  }

  .icn-global {
    top: 3px;
    position: relative;
  }
}

.uk-nav-default {
  > li {
    > a {
      color: #fff !important;
    }
  }
}

// APK SCSS
.apk-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .apk-header {
    padding: 15px 20px;
    box-shadow: 0px 2px 3px $black_90;
  }
  .apk-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    .apk-demo {
      margin-top: 40px;
      .apk-content {
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
    .download-apk {
      display: flex;
      align-self: center;
    }
  }

  .apk-logo {
    width: 74px;
    .download-apk {
      display: flex;
      align-self: center;
    }
  }
  .apk-logo {
    width: 74px;
  }
}

.color-grey-65 {
  color: $grey_65;
}

// APK SCSS end

// terms conditions
.terms-parent {
  header {
    padding: 15px 20px;
    box-shadow: 0px 2px 3px $black_90;
  }

  .terms-main {
    padding: 15px 20px;
  }
}

.alert-default{
  border-radius: 8px;
  padding: 10px 14px;
  gap: 20px !important;
  margin: 0;
  // max-width: 940px; set in custom css
  background-color: $grey_80;
  box-shadow: $black_56 0px 0px 10px 4px;
  color: $white;
  .line{
    width: 4px;
    height: 40px;
    border-radius: 30px;
    &.alert-success{
      background: $turquoise;
    }
    &.alert-danger{
      background: $red_90;
    }
    &.alert-warning{
      background: $orange;
    }
  }
  .alert-close{
    box-shadow: $black_56 0px 0px 10px 4px;
    border-radius: 50%;
  }
}
// terms conditions End

.no-data-available {
  height: calc(100vh - 73px)
}

.toast {
  position: absolute;
  top: 25px;
  right: 30px;
  overflow: hidden;
  padding-right: 65px;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
  font-size: 20px;
  font-weight: 400;
  &.active{
    transform: translateX(0);
  }
  .toast-close{
    position: absolute;
    top: 17px;
    right: 15px;
    padding: 2px;
    cursor: pointer;
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }
  &-progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: $grey_60;
    &:before{
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $grey_95;
    }
    &.active{
      &:before{
        animation: progress 5s linear forwards;
      }
    }
  }
}
@keyframes progress {
  100%{
    left: 100%;
  }
}

.sub-title {
  font-size: 20px;
  margin: 30px 0 0 30px;
}

.qr-screen {
  padding: 40px 80px 0px 80px;
  min-height: calc(100vh - 109px);
  gap: 100px;

  .qr-part-one {
    justify-content: space-evenly;

    .heading-part {
      margin-bottom: 40px;
    }

    .qr-area-parent {
      margin-bottom: 40px;
      column-gap: 40px;
      justify-content: space-evenly;

      .qr-area-first {
        row-gap: 80px;

        .qr-area-box {
          width: 290px;
          height: 290px;
          border-radius: 12px;
          background-color: $grey_20;
          border: 1px solid $grey_95;
        }
      }
    }
  }

  .qr-part-two {
    padding: 130px 60px 30px;
    gap: 20px;
    background: url('../images/img-mobile-frame.svg') no-repeat top;
    width: 620px;
    background-size: 100%;
    min-height: 700px;

    .heading-part {
      color: $grey_80;

      span {
        font-size: 5rem;
      }

      p {
        font-size: 24px;
      }
    }

    .qr-area-box {
      width: 430px;
      height: 430px;
      border-radius: 12px;
      background-color: $grey_20;
      border: 1px solid $grey_95;
    }
  }
}

.font-120 {
  font-size: 120px;
}
// Develop css
.table-parent {
  position: absolute;
  z-index: 10;
  width: calc(100% - var(--side-menu-width));
  right: 0;
  top: 0;
  height: 100%;
  pointer-events: none;

  .table-area {
    pointer-events: all;
  }
}
// responsive scss
@media (min-width: 1200px) {
  .uk-mt-150 {
    margin-top: 150px !important;
  }
}

@media (max-width: 1500px) {
  .qr-screen {
    padding: 40px 60px 0px 60px;
    gap: 50px;

    .qr-part-one {
      .heading-part {
        span {
          font-size: 60px;
        }

        p {
          font-size: 20px;
        }
      }

      .qr-area-parent {
        margin-bottom: 40px;
        column-gap: 40px;
        justify-content: space-evenly;

        .qr-area-first {
          row-gap: 40px;

          .qr-area-box {
            width: 200px;
            height: 200px;
            border-radius: 12px;
            background-color: $grey_20;
            border: 1px solid $grey_95;
          }
        }
      }
    }

    .qr-part-two {
      padding: 100px 60px 30px;
      gap: 20px;
      width: auto;
      background-size: 100%;
      min-height: 300px;

      .heading-part {
        color: $grey_80;

        span {
          font-size: 3rem;
        }

        p {
          font-size: 24px;
        }
      }

      .qr-area-box {
        width: 300px;
        height: 300px;
      }
    }
  }
}
@media screen and (min-width: 1800px) and (max-width: 1919px) {
  .footer {
    .f-child {
      column-gap: 40px !important;

      .sec-part {
        column-gap: 20px !important;
      }
    }
  }

  .side-menu {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1799px) {
  .footer {
    .uk-overflow-scroll {
      overflow-x: auto;
    }

    .max-w-976 {
      max-width: 976px;
    }

    .uk-pader {
      max-width: calc(100% - 203px);
      width: 100%;
    }
  }

  .side-menu {
    padding-top: 20px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1365px) {
  .uk-container-pader {
    padding: 20px;

    .hsse-logo {
      img {
        width: 100px;
      }

      .normal-mode-heading {
        font-size: 24px;
        margin-top: 20px;
      }
    }

    .media-right {
      .uk-dropdown-comman {
        width: auto !important;
        top: 50px !important;
        padding: 12px 20px !important;

        .uk-nav {
          span:not(.add-disaster-info) {
            font-size: 20px;
          }

          input {
            &.uk-radio {
              width: 20px;
              height: 20px;

              &:checked {
                background-size: 46px !important;
              }
            }
          }
        }
      }

      .top-item {
        li {
          margin-right: 30px;

          &:last-child {
            margin-right: 0px;
          }
        }

        .uk-language {
          a {
            column-gap: 10px !important;

            .icn-global {
              width: 30px;
              height: 30px;
            }

            span {
              font-size: 24px;
            }
          }
        }

        .uk-power-plant {
          a {
            column-gap: 10px !important;

            .outline-building {
              width: 30px;
              height: 30px;
            }

            span {
              font-size: 24px;
            }
          }

          .uk-dropdown-comman {
            min-width: 300px !important;
            padding: 12px 4px 12px 15px !important;

            .uk-nav {
              max-height: 310px;

              li {
                margin-right: 0px;

                a {
                  label {
                    margin-top: 0px;
                  }
                }
              }

              input {
                &.uk-radio {
                  width: 20px;
                  height: 20px;

                  &:checked {
                    background-size: 46px !important;
                  }
                }
              }
            }
          }
        }

        .uk-aki-suzuki {
          a {
            column-gap: 10px !important;

            span {
              font-size: 24px;
            }
          }

          .uk-dropdown-comman {
            top: 70px !important;

            .user-pic {
              width: 120px;
              height: 120px;
              margin-right: 30px;
            }

            .user-details {
              h3 {
                font-size: 30px;
                padding-top: 0px;
              }

              p {
                font-size: 16px;
              }

              button {
                min-width: 170px;
              }
            }
          }
        }

        .uk-menu-icn {
          .icn-menu {
            width: 30px;
            height: 30px;
          }

          .menu-icn {
            padding: 12px 10px;
            right: 0;
            width: 350px;

            ul {
              li {
                margin-right: 0px;

                a {
                  font-size: 16px;

                  &.selected-bg {
                    padding: 6px 10px !important;
                    margin-right: 0px !important;
                  }

                  img {
                    width: 24px;
                    height: 24px;
                  }

                  span {
                    font-size: 16px;
                  }
                }

                ul {
                  li {
                    padding: 4px 10px !important;

                    a {
                      div {
                        column-gap: 10px !important;

                        img {
                          width: 30px;
                          height: 30px;
                        }
                      }

                      label {
                        input {
                          &.uk-radio {
                            width: 20px;
                            height: 20px;

                            &:checked {
                              background-size: 46px !important;
                            }
                          }
                        }
                      }
                    }

                    button {
                      min-width: 100px;
                      padding: 0 20px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .bottom-item {
        margin-top: 20px;

        .bottom-right {
          label {
            margin-right: 20px;
            column-gap: 10px !important;
            align-items: flex-end;

            input {
              height: 24px;
              width: 24px;
            }

            span {
              font-size: 20px;
            }
          }

          .uk-button-orange {
            column-gap: 10px !important;
            height: 40px;
            padding: 0px 18px 0px 18px;

            img {
              width: 24px;
              height: 24px;
            }

            span {
              font-size: 16px;
            }
          }
        }

        .select-disaster {
          min-width: 240px;

          li {
            a {
              span {
                font-size: 16px;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .uk-dropdown-comman {
            width: 100% !important;
          }
        }
      }
    }
  }

  .side-menu {
    height: calc(100vh - 246px);
    padding-top: 20px;
  }

  .uk-map {
    height: calc(100vh - 246px);
  }

  .footer {
    min-height: auto;

    .f-child {
      column-gap: 20px;

      .situation-heding {
        font-size: 24px;
      }

      .uk-pader {
        max-width: calc(100% - 116px);

        .both-dropdown-nav {
          .f-box {
            min-width: 104px;

            li {
              a {
                span {
                  font-size: 14px;
                }
              }

              &:last-child {
                font-size: 16px;
              }
            }

            .uk-dropdown {
              // top: -300px !important;
              min-width: 220px;
              left: auto !important;

              .company-selection {
                font-size: 20px;
                padding: 10px;
              }

              ul {
                max-height: 234px;
                padding: 10px;

                li {
                  a {
                    label {
                      margin-top: 0px;

                      span {
                        font-size: 16px;
                      }

                      input {
                        background-size: 46px !important;

                        &.uk-radio {
                          width: 20px;
                          height: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .sec-part {
          overflow: auto;
          column-gap: 30px;

          .f-box {
            min-width: fit-content;

            label {
              input {
                width: 20px;
                height: 20px;
              }

              span {
                font-size: 16px;
              }
            }

            .bottom-text {
              span {
                font-size: 18px;

                &:last-child {
                  font-size: 10px;
                }
              }
            }

            .evacuation {
              span {
                font-size: 16px;
              }
            }

            .count-text {
              margin-top: 10px;

              span {
                font-size: 24px;

                &.sup-text {
                  font-size: 10px;
                }
              }
            }

            &.switch-btn {
              span {
                font-size: 16px;
              }

              h3 {
                margin-top: 6px;

                label {
                  .switch {
                    width: 48px;
                    height: 24px;

                    input:checked + .switch::before {
                      left: 27px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .f-box {
      .f-box-value {
        font-size: 18px;
      }
    }
  }

  .modal-area {
    .modal-dialog {
      &.min-h-530 {
        min-height: 140px !important;
      }
    }
  }

  .modal-area {
    .modal-dialog {
      &.min-h-530 {
        min-height: 140px !important;
      }
    }
  }
}

@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .footer {
    .uk-pader {
      max-width: calc(100% - 170px);
    }

    .uk-overflow-scroll {
      overflow-x: auto;
    }
  }

  .header .uk-button-orange {
    min-width: 230px;
  }
}

// For all responsive
@media (max-width: 1800px) {
  .header {
    padding: 15px 20px;

    .logo {
      width: 100px;
    }

    .normal-mode-heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 500;
      line-height: 1;
    }

    .mt-30 {
      margin-top: 20px !important;
    }

    .uk-button-orange {
      height: 38px !important;
      padding: 0px 15px 0px 15px !important;
      column-gap: 8px !important;
      min-width: 130px;

      .font-28 {
        font-size: 16px !important;
      }

      img {
        width: 26px;
      }
    }

    .select-disaster {
      border-radius: 6px !important;
      padding: 2px 0px !important;
      min-width: 220px !important;
      height: 38px !important;

      .font-24 {
        font-size: 18px !important;
      }

      .add-disaster-info {
        font-size: 16px !important;
      }

      > li {
        > a {
          padding: 0 !important;
          line-height: 30px;
        }
      }

      .icn-down-arrow {
        width: 16px !important;
      }

      .custom-dropdown {
        width: 100% !important;
      }
    }

    .bottom-right {
      .mr-52 {
        margin-right: 30px !important;
        column-gap: 10px !important;

        .font-24 {
          font-size: 16px !important;
          font-weight: 400 !important;
        }

        .uk-checkbox {
          height: 26px !important;
          width: 26px !important;
        }
      }
    }

    .media-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }

  .acn-btns {
    justify-content: flex-end;

    > li {
      margin-right: 30px;

      a {
        gap: 10px !important;
        line-height: 1;
        padding: 0px;

        .icn-primary {
          width: 24px;
        }

        span {
          font-size: 20px;
        }

        .icn-down-arrow {
          width: 16px;
          position: relative;
          top: 3px;
        }
      }

      label {
        line-height: 1;

        &.mt-17,
        &.mt-18 {
          margin-top: 0px !important;
        }
      }

      &.uk-menu-icn {
        margin-right: 0px;

        .icn-menu {
          width: 30px;
        }
      }
    }
  }

  .custom-dropdown {
    padding: 15px !important;
    width: auto !important;

    ul {
      > li {
        margin: 0px !important;
        padding: 10px 0px !important;

        label {
          line-height: 1;

          .font-28 {
            font-size: 16px !important;
          }

          &.mt-20 {
            margin-top: 0px !important;
          }
        }
      }
    }

    .label-text {
      font-size: 18px !important;
    }

    .uk-radio {
      width: 24px;
      height: 24px;

      &:checked {
        background-size: 60px !important;
      }
    }

    .uk-checkbox {
      height: 24px;
      width: 24px;
    }

    &.power-plant {
      min-width: 280px !important;
    }

    .user-pic {
      width: 100px !important;
      height: 100px !important;
      margin-right: 20px !important;
      min-width: 100px !important;
      min-width: 100px !important;
    }

    .user-details {
      h3 {
        line-height: 1 !important;
        padding: 0 !important;
        font-size: 26px !important;
        margin-top: 5px !important;
      }

      p {
        line-height: 1 !important;
        font-size: 14px !important;
        margin: 10px 0 15px !important;
      }

      .uk-btn-logout {
        height: 24px !important;
        line-height: 1 !important;
        min-width: auto !important;
        font-size: 14px !important;
      }
    }

    &.hamburger-dropdown {
      min-width: 300px !important;

      ul {
        > li {
          padding: 5px 0px !important;

          a {
            font-size: 16px !important;
            padding: 6px 10px 6px 16px !important;
            margin-right: 0px !important;

            .icn-down-arrow {
              top: 1px;
            }
          }

          .uk-nav-sub {
            li {
              .column-gap-20 {
                gap: 10px !important;
              }

              a {
                padding: 0px 10px !important;

                img {
                  width: 40px !important;
                  height: 40px !important;
                }

                span,
                label {
                  font-size: 14px !important;
                }
              }

              .uk-btn-logout {
                height: 24px !important;
                line-height: 1 !important;
                min-width: 102px !important;
                font-size: 14px !important;
                padding: 0 15px !important;
              }
            }
          }
        }
      }
    }

    &.f-custom-dropdown {
      padding: 0px !important;
      min-width: 280px !important;

      .company-selection {
        font-size: 18px !important;
        padding: 5px 10px !important;
      }

      label {
        margin: 0px !important;

        .font-32 {
          font-size: 16px !important;
        }
      }

      .uk-navbar-dropdown-nav {
        padding: 10px !important;

        > li {
          > a {
            padding: 0px !important;
          }
        }
      }
    }
  }

  .uk-map {
    // height: calc(100vh - 204px) !important;
  }

  :root {
    --side-menu-width: 130px;
  }

  .side-menu {
    height: calc(100vh - 204px) !important;
    width: var(--side-menu-width);

    .sd-box {
      padding: 0 10px;
      margin-bottom: 30px !important;

      h2 {
        font-size: 22px !important;
        line-height: 1 !important;
        margin-bottom: 7px !important;
        margin-top: 0px !important;
      }

      p {
        line-height: 1.2;
        font-size: 16px;
        margin: 0px !important;
      }

      .icn-up-direcrion {
        width: 30px !important;
        margin-bottom: 10px !important;
      }
    }

    .uk-margin-medium-bottom {
      margin-bottom: 20px !important;
    }

    .icn-compass {
      width: 70px !important;
    }
  }

  .footer {
    padding: 10px 15px !important;
    min-height: auto !important;

    .uk-pader {
      max-width: initial !important;
    }

    .font-36 {
      font-size: 24px !important;
    }

    .column-gap-60 {
      column-gap: 40px;
    }

    .font-22 {
      font-size: 16px !important;
      white-space: nowrap;
    }

    .uk-nav-default {
      > li {
        > a {
          color: #fff !important;
        }
      }
    }

    .icn-down-arrow {
      width: 22px !important;
    }

    .mr-20 {
      margin-right: 10px;
    }

    .f-box {
      .f-box-value {
        font-size: 20px !important;
        line-height: 1 !important;
        font-weight: 500 !important;
      }

      .uk-checkbox {
        width: 22px !important;
        height: 22px !important;
        top: 1px !important;
        top: 1px !important;
      }

      &.min-w-200 {
        min-width: 150px !important;

        &:nth-child(3) {
          min-width: 100px !important;
        }
      }

      .font-34,
      .font-38,
      .font-32 {
        font-size: 24px !important;
        line-height: 1 !important;
      }

      .font-20 {
        font-size: 14px !important;
      }

      .mt-10 {
        margin-top: 8px !important;
      }

      .font-18,
      .font-19 {
        font-size: 16px !important;
        white-space: nowrap;
      }

      &.switch-btn {
        h3 {
          line-height: 1 !important;
        }

        .mt-15 {
          margin-top: 2px !important;
        }

        .switch {
          width: 70px;
          height: 26px;
          margin: 0 auto;

          &:before {
            width: 24px;
            height: 24px;
          }
        }
      }

      label {
        display: flex;
      }
    }

    .column-gap-20 {
      column-gap: 10px !important;
    }

    .column-gap-40 {
      column-gap: 27px !important;
    }

    .max-w-976 {
      max-width: inherit !important;
      overflow-y: hidden !important;
    }

    .min-w-160 {
      min-width: auto !important;
    }
  }

  .f-custom-dropdown {
  }

  .table-area {
    // top: 123px;
    width: calc(100% - 0px);
    height: calc(100vh - 203px);

    &.table-minimize {
      top: 130px;
    }

    .uk-padding-small {
      padding: 10px;
    }

    .icn-maximize,
    .icn-close-table {
      img {
        width: 20px;
      }
    }

    .icn-minimize {
      img {
        width: 16px;
      }
    }

    .table-data {
      th {
        font-size: 16px !important;
        padding: 5px !important;
        vertical-align: middle;
        vertical-align: middle;
      }

      td {
        padding: 4px !important;
        vertical-align: middle;
      }
    }
  }

  .disaster-location {
    top: 160px !important;
    width: fit-content !important;

    .uk-padding-small {
      padding: 12px !important;
      column-gap: 20px !important;
    }

    .title {
      line-height: 1;
      font-size: 34px;
    }

    .icn-danger-disaster {
      width: 60px;
    }
  }

  .modal-area {
    top: 118px;
    height: calc(100% - 207px);
    z-index: 99999;

    .modal-dialog {
      width: 50% !important;

      &.min-h-530 {
        min-height: 300px;
      }

      .modal-title-3 {
        margin: 0 !important;
        text-align: center !important;
        font-size: 34px !important;
      }

      .tsunami-present-label {
        font-size: 23px;
      }

      .modal-p-text {
        font-size: 28px !important;
        margin: 10px 0px 40px;
      }

      .modal-h2-title {
        margin-top: 20px !important;

        &.modal-h2-title-lg {
          font-size: 40px !important;
        }
      }

      .warning-area .square-repetition {
        height: 30px;
        background-size: 39px !important;
      }

      .warning-heading {
        font-size: 36px !important;
      }

      .uk-button-link {
        font-size: 30px !important;
        line-height: 1;
      }

      .modal-title {
        font-size: 60px !important;
      }

      .modal-description {
        font-size: 30px !important;
      }
    }

    // make content behind confirm popup not accessible
    &:after {
      display: block;
      width: 100%;
      height: 100%;
      content: " ";
      position: fixed;
      z-index: -1;
      top: 0;
    }
  }
}

@media (max-width: 1560px) {
  .filter-ellipses-label {
    max-width: 150px;
  }
}

// responsive scss End

@media screen and (max-width: 1439px) {
  .footer {
    .uk-pader {
      max-width: calc(100% - 136px) !important;
    }

    .table-area {
      .table-data {
        .w-310 {
          width: auto;
        }
      }
    }
  }
}

.default-cursor {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.align-items-end {
  align-items: end;
}

.uk-button {
  // transition: all 0.3s ease;
}

.uk-button:disabled {
  // background: linear-gradient(274.22deg, #007cbb 0.81%, #54afde 96.42%) !important;
  cursor: not-allowed;
}

.uk-button-orange:disabled {
  background: linear-gradient(
    274.22deg,
    $orange 0.81%,
    $orange_90 96.42%
  ) !important;
}

.uk-button-orange.btn-active-mode:disabled {
  background: linear-gradient(
    274.22deg,
    $grey_50 0.81%,
    $grey_30 96.42%
  ) !important;
}

.uk-button-orange.btn-active-mode {
  display: block;
  margin: 0 auto;
  line-height: 1;
}

.uk-button:disabled {
  cursor: not-allowed;
  border: none;
}

.uk-checkbox:disabled {
  border: 1px solid $grey_60 !important;
  cursor: not-allowed;
}

.uk-nav.select-disaster.cursor-not-allowed {
  border: 1px solid $grey_60;
}

.uk-radio:disabled {
  cursor: not-allowed;
}

.switch.disabled,
.uk-nav-sub li.disabled .label-container,
.uk-button:disabled,
.uk-radio:disabled,
.uk-checkbox:disabled,
.uk-checkbox:disabled + .checkbox-label-text,
.opacity-half {
  opacity: 0.5;
}

.flex-grow-1 {
  flex: 1 !important;
}

.flex-grow {
  flex-grow: 1;
}

/* Fullscreen overlay */
.overlay {
  position: fixed;
  /* Stay in place */
  top: 0;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with transparency */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  z-index: 10000;
  /* Make sure it's on top of other elements */
}

.custom-popup::after {
  opacity: 0;
}

.custom-items-popup {
  border-radius: 5px;
  margin-top: -17px !important;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  z-index: 40;
}

.text-nowrap {
  text-wrap: nowrap;
}

/* CSS for the popup arrow starts*/
.custom-items-popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.custom-items-popup .maplibregl-popup-content {
  pointer-events: auto;
  position: relative;
  background: linear-gradient(
    208deg,
    rgba(31, 35, 40, 1) 0%,
    #1a1c1f 100%
  ) !important;
  border: 2px solid $map_marker_tooltip;
  border-radius: 8px !important;
  color: white;
  padding: 10px 10px !important;
}

.maplibregl-popup-anchor-bottom.custom-items-popup .maplibregl-popup-tip,
.maplibregl-popup-anchor-top.custom-items-popup .maplibregl-popup-tip {
  border-top-color: $map_marker_tooltip;
  border-bottom-color: $map_marker_tooltip;
}

.custom-items-popup::after {
  opacity: 0;
}
/* CSS for the popup arrow ends */