.Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--light {
  background: none !important;
  color: inherit !important;
  padding: 0 !important;
}

.Toastify__close-button--light {
  display: none !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__toast-container {
  --toastify-toast-width: 940px !important;
  width: auto !important;
  max-width: var(--toastify-toast-width);

  .toast.alert-default {
    position: static;
    margin: 10px; /* to apply box shadow stylings */
  }
}

.custom-zoom-controls {
  position: absolute;
  bottom: 10px;  /* Place controls at the bottom */
  right: 20px;   /* Place controls at the right */
  display: flex;
  flex-direction: column;
}

.custom-zoom-controls button {
  background: none;  /* Remove background */
  border: none;      /* Remove borders */
  padding: 0;        /* Remove padding */
  cursor: pointer;   /* Show pointer cursor on hover */
  outline: none;     /* Remove focus outline */
  margin-bottom: 10px; /* Add some space between the buttons */
  transition: opacity 0.3s ease, transform 0.5s ease; /* Add smooth transition for opacity and transform */
}

.custom-zoom-controls button img {
  width: 30px;      /* Set the width of the SVG icon */
  height: 30px;     /* Set the height of the SVG icon */
}

.custom-zoom-controls button:not(:disabled):hover {
  opacity: 0.85; /* Decrease opacity on hover for a subtle fade effect */
  transform: scale(1.05); /* Slight scaling for a subtle visual pop effect */
}

.custom-zoom-controls button:not(:disabled):active {
  transform: scale(1); /* Reset scaling when clicked */
  // opacity: 1; /* Restore full opacity when the button is clicked */
}

.custom-zoom-controls button:disabled {
  cursor: not-allowed;
}

.custom-zoom-controls button:not(:disabled) {
  cursor: pointer;
}

// @TODO: need to update the popup to latest design
/* Custom CSS for the popup */
.custom-popup {
  border-radius: 5px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px !important;
  // left: 100px !important;
}

.custom-popup-user {
  border-radius: 5px;
  padding: 10px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px !important;
  color: #333;
  margin-bottom: 10px !important;
  min-width: 300px !important;
}

/* CSS for the popup arrow */
/* @TODO: Need to update the tooltip according to XD design (pending) */

.custom-popup::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

// .custom-popup-user::after {
//   content: '';
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -10px;
//   border-width: 10px;
//   border-style: solid;
//   border-color: white transparent transparent transparent;
// }

.custom-popup .maplibregl-popup-content {
  pointer-events: auto;
  position: relative;
  background-color: #474747;
  border: 2px solid #474747;
  border-radius: 10px !important;
  color: white;
  padding: 5px 10px !important;
  // word-break: break-word;
  // margin-bottom: 50px !important;
}

.custom-popup-user .maplibregl-popup-content {
  pointer-events: auto;
  position: relative;
  background-color: #474747;
  border: 2px solid #474747;
  border-radius: 10px !important;
  color: white;
  padding: 5px 10px !important;
  word-break: break-word;
  // margin-bottom: 50px !important;
}

.custom-popup-user .maplibregl-popup-content .user-location-popup {
  display: flex;
  align-items: center;
  .user-status-icon-container {
    margin-right: 10px !important;
  }
}

.custom-popup .maplibregl-popup-content .cluster-content-container {
  font-family: 'Noto Sans JP', sans-serif;
  color: rgba(255,255,255,.7);
  padding: 5px !important;
  p {
    margin: 0px !important;
    margin-bottom: 10px !important;
  }
  p:last-child {
    margin-bottom: 0px !important;
  }
}

.custom-popup::after {
  opacity: 0;
}

// .custom-popup-user::after {
//   opacity: 0;
// }

// user tooltip anchor
.custom-popup-user.maplibregl-popup-anchor-bottom .maplibregl-popup-tip{
  border-top-color: #474747 !important;
  margin-bottom: 15px !important;
}
.custom-popup-user.maplibregl-popup-anchor-top .maplibregl-popup-tip{
  border-bottom-color: #474747 !important;
  margin-top: 15px !important;
}
.custom-popup-user.maplibregl-popup-anchor-left .maplibregl-popup-tip{
  border-right-color: #474747 !important;
  margin-left: 15px !important;
}
.custom-popup-user.maplibregl-popup-anchor-right .maplibregl-popup-tip{
  border-left-color: #474747 !important;
  margin-right: 15px !important;
}
.custom-popup-user.maplibregl-popup-anchor-top-left .maplibregl-popup-tip {
  border-bottom-color: #474747 !important;
  border-right-color: transparent !important;
  border-width: 20px !important;
  margin-left: 10px !important;
}
.custom-popup-user.maplibregl-popup-anchor-top-right .maplibregl-popup-tip {
  border-bottom-color: #474747 !important;
  border-left-color: transparent !important;
  border-width: 20px !important;
  margin-right: 10px !important;
  // margin-top: 30px !important;
}
.custom-popup-user.maplibregl-popup-anchor-bottom-left .maplibregl-popup-tip {
  border-top-color: #474747 !important;
  border-right-color: transparent !important;
  border-width: 20px !important;
  margin-left: 10px !important;
}
.custom-popup-user.maplibregl-popup-anchor-bottom-right .maplibregl-popup-tip {
  border-top-color: #474747 !important;
  border-left-color: transparent !important;
  border-width: 20px !important;
  margin-right: 10px !important;
}


.custom-popup.maplibregl-popup-anchor-bottom .maplibregl-popup-tip{
  border-top-color: #474747 !important;
  margin-bottom: 40px !important;
}
.custom-popup.maplibregl-popup-anchor-top .maplibregl-popup-tip{
  border-bottom-color: #474747!important;
  margin-top: 40px !important;
}
.custom-popup.maplibregl-popup-anchor-left .maplibregl-popup-tip{
  border-right-color: #474747!important;
  margin-left: 40px !important;
}
.custom-popup.maplibregl-popup-anchor-right .maplibregl-popup-tip{
  border-left-color: #474747!important;
  margin-right: 40px !important;
}
.custom-popup.maplibregl-popup-anchor-top-left .maplibregl-popup-tip {
  border-bottom-color: #474747 !important;
  border-right-color: transparent !important;
  border-width: 20px !important;
  margin-left: 10px !important;
}
.custom-popup.maplibregl-popup-anchor-top-right .maplibregl-popup-tip {
  border-bottom-color: #474747 !important;
  border-left-color: transparent !important;
  border-width: 20px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
}
.custom-popup.maplibregl-popup-anchor-bottom-left .maplibregl-popup-tip {
  border-top-color: #474747 !important;
  border-right-color: transparent !important;
  border-width: 20px !important;
  margin-left: 10px !important;
}
.custom-popup.maplibregl-popup-anchor-bottom-right .maplibregl-popup-tip {
  border-top-color: #474747 !important;
  border-left-color: transparent !important;
  border-width: 20px !important;
  margin-right: 20px !important;
  margin-bottom: 30px !important;
}

.filter-ellipses-label {
  max-width: 250px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tsunami-present-label {
  margin-bottom: -20px;
  margin-top: 24px;
  padding-bottom: 24px;
  font-size: 33px;
}
