@import 'variables';

/* noto-sans-jp-regular - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts2/noto-sans-jp-v36-latin_japanese-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts2/noto-sans-jp-v36-latin_japanese-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-regular.svg#NotoSansJP') format('svg');
  /* Legacy iOS */
}

/* noto-sans-jp-500 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts2/noto-sans-jp-v36-latin_japanese-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts2/noto-sans-jp-v36-latin_japanese-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-500.svg#NotoSansJP') format('svg');
  /* Legacy iOS */
}

/* noto-sans-jp-700 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts2/noto-sans-jp-v36-latin_japanese-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts2/noto-sans-jp-v36-latin_japanese-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts2/noto-sans-jp-v36-latin_japanese-700.svg#NotoSansJP') format('svg');
  /* Legacy iOS */
}